export const URL_CLIENT_VIEW = "https://sputnik.reachmedia.co.nz/";

export const URL_CX_VIEW = "https://sputnik.reach.net.nz/";

export const URL_GEO_API = "https://geo-dot-prod-sputnik.ts.r.appspot.com";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDukeu8dxAUrZIlULlo8R286NEUCW_jDqo",
    authDomain: IS_CLIENT_APP
        ? "sputnik.reachmedia.co.nz"
        : "sputnik.reach.net.nz",
    databaseURL: "https://prod-sputnik.firebaseio.com",
    projectId: "prod-sputnik",
    storageBucket: "prod-sputnik.appspot.com",
    messagingSenderId: "235709282929",
    appId: "1:235709282929:web:483fb3bb344b2c41",
};

export const CUSTOM_OAUTH_PARAMETERS = {
    hd: "reach.nz",
};

export const QUOTE_PDF_TRIGGER_URL = "https://hook.integromat.com/po2huwqr02if75ujuax1jwm9akpj1zir";

export const QUOTE_BOOKING_URL =  "https://quote-booking.reach.net.nz/quotes";

export const LIBERTY_JOB_URL =    "https://ga31b0306967e33-libertyprod.adb.ap-sydney-1.oraclecloudapps.com/ords/f?p=100:101:::::P101_JOB_ID:";

export const OCI__API_REACH_NET_NZ = "https://api.reach.net.nz";
